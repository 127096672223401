import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../enviroment/enviroment.dev';
import { UtilsService } from './utils.service';
@Injectable({

  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http:HttpClient,
    private utilSvc: UtilsService) { }

  auth(data:any){
    return this.http.post(environment.baseUrl + environment.login, data, {headers: this.utilSvc.getHttpHeaders()});
  }
}
