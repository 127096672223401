<h2 class="mb-2">Catálogo de {{type}}</h2>

<div class="p-3">
   
    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Buscar</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Buscar"
          #input
        /> 
      </mat-form-field>

    <div *ngIf="type == 'Operadoras'" style="display: flex; justify-content: end;">
        <button color="primary" mat-raised-button class="btn" (click)="openDialog()">
            Añadir Operadora
        </button>
    </div>

    <table mat-table class="lessons-table mat-elevation-z8" [dataSource]="dataSource" matSort #empTbSort="matSort" matSort (matSortChange)="sortData($event)">
        <div *ngIf="type != 'Ordenes'">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ formatName(column) }}</th>
                <td mat-cell *matCellDef="let emp">{{ emp[column] }}                    
                        <button color="primary" *ngIf="column == 'acciones'" class="bt-list" mat-raised-button (click)="openDialogPlan(emp)"
                        title="Agregar un plan">
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>

                        <button color="primary" *ngIf="column == 'fondear'" class="bt-list" mat-raised-button (click)="openDialogDeposit(emp)"
                        title="Fondear Efectivo">
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>

                        <button color="primary" *ngIf="column == 'acciones'" mat-raised-button class="bt-list" (click)="openDialogPlanList(emp)"
                        title="Ver lista de planes">
                            <mat-icon class="icon-menu">assignment</mat-icon>
                        </button>
                </td>
              </ng-container>
        </div>

        <div *ngIf="type == 'Ordenes'">
            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
                <td mat-cell *matCellDef="let lesson">{{lesson.fecha | date:'dd/MM/yy'}} </td>
            </ng-container>                      

            <ng-container matColumnDef="tipo_servicio">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Servicio</th>
                <td mat-cell class="duration-cell"
                          *matCellDef="let lesson">{{lesson.tipo_servicio}}
                </td>
            </ng-container>

            <ng-container matColumnDef="hash">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Hash</th>
                <td mat-cell class="duration-cell"
                          *matCellDef="let lesson">{{lesson.hash}}
                </td>
            </ng-container>
            
        
            <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
                <td mat-cell class="description-cell"
                          *matCellDef="let lesson">{{lesson.tipo}}</td>
            </ng-container>
        
            <ng-container matColumnDef="total_usd">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Total USD</th>
                <td mat-cell class="duration-cell"
                          *matCellDef="let lesson">{{lesson.total_usd}}</td>
            </ng-container>

            <ng-container matColumnDef="operadora">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Operadora</th>
                <td mat-cell class="duration-cell"
                          *matCellDef="let lesson">{{lesson.operadora}}</td>
            </ng-container>

            <ng-container matColumnDef="pais">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>País</th>
                <td mat-cell class="duration-cell"
                          *matCellDef="let lesson">{{lesson.pais}}</td>
            </ng-container>

            <ng-container matColumnDef="destino">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Destino</th>
                <td mat-cell class="duration-cell"
                          *matCellDef="let lesson">{{lesson.destino}}</td>
            </ng-container>

            <ng-container matColumnDef="estatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Estatus</th>
                <td mat-cell class="duration-cell"
                          *matCellDef="let lesson">{{lesson.estatus}}</td>
            </ng-container>
        </div>

    
        <!--mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row-->
        <!--ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
            <td mat-cell *matCellDef="let emp">{{ emp[column] }}</td>
        </ng-container-->
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let emprow; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow aria-colspan="fill" >
            <td class="mat-cell text-center" colspan="5">
                No se encontraron registros.
            </td>
        </tr>
    </table>
    <mat-paginator
        #paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
    ></mat-paginator>
</div>
