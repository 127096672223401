import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponentComponent } from './login-component/login-component.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CreateComerceComponent } from './create-comerce/create-comerce.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    component: LoginComponentComponent
  },
  {
    path: 'home',
    component: DashboardHomeComponent
  },
  {
    path: 'catalog/:type',
    component: CatalogComponent
  },
  {
    path: 'createComerce',
    component: CreateComerceComponent
  },
  {
    path: 'notifications',
    component: CreateComerceComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
