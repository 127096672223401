import { Component } from '@angular/core';

@Component({
  selector: 'create-comerce',
  templateUrl: './create-comerce.component.html',
  styleUrls: ['./create-comerce.component.css']
})
export class CreateComerceComponent {

}
