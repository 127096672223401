<div class="content">
    <form [formGroup]="registerForm" class="w-80 p-3" novalidate (ngSubmit)="onSubmit()">

        <mat-card class="shipping-card">

            <mat-card-header class="form-header">
                <mat-card-title class="">Datos de la operadora</mat-card-title>
              </mat-card-header>

            <mat-card-content>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Nombre</span> </label>
                    <input class="input-custom" matInput placeholder="Nombre" formControlName="nombre" name="nombre">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Código</span> </label>
                    <input class="input-custom" matInput placeholder="Código" formControlName="codigo" name="codigo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Descripcion</span> </label>
                    <input class="input-custom" matInput placeholder="descripcion" formControlName="descripcion" name="descripcion">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Url Imágen</span> </label>
                    <input class="input-custom" matInput placeholder="imagen" formControlName="image" name="image">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>País</span> </label>
                    <select class="input-custom" matInput placeholder="País" formControlName="pais" name="image" (change)="selectPais($event)">
                        <option value="{{pais.codigo_iso}}" *ngFor="let pais of paises" (change)="selectPais(pais)">{{pais.nombre}}</option>
                    </select>
                </div>
            </div>
            <div class="errors-container">
                
            </div>

            <div class="row">
                <mat-card-actions class="d-flex center">
                    <button mat-raised-button 
                       [disabled]="
                       registerForm.controls.moneda.errors?.['required'] || 
                       registerForm.controls.nombre.errors?.['required'] ||
                       registerForm.controls.image.errors?.['required'] ||
                       registerForm.controls.pais.errors?.['required'] ||
                       registerForm.controls.descripcion.errors?.['required'] ||
                       registerForm.controls.codigo.errors?.['required']"
                    color="primary" class="btn" type="submit">
                    Registrar
                    </button>
               </mat-card-actions>
            </div>

            </mat-card-content>
        </mat-card>

    </form>
</div>