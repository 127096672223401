export const environment = {
    production: true,
    
    //==== Orbita Back ====
    // DEV
    //baseUrl: 'https://dev.back.superorbita.com/api/v1/',
    //baseUrl: "https://dev.comercioback.superorbita.com/api/v1/",
     baseUrl: "https://comercioback.superorbita.com/api/v1/",
    // PROD
//     baseUrl: 'https://back.superorbita.com/api/v1/',
  

  //==== Auth & Comerce INFO ====
  login: 'cliente/login',
  client: 'cliente',  
  api_key: "cliente/api_key",
  balance: "cliente/balance",
  balance_hurray: "catalogo/proveedor/huuray/balance",
  balance_ding: "catalogo/proveedor/ding/balance",

  //==== Catalogs ====
  operators: "catalogo/operadora", 
  catalogo_suscripcion: "catalogo/suscripcion",
  orders: "orden/",
  allOrders: "orden/backoffice",
  paises: "catalogo/pais",
  servicio: "catalogo/servicio",
  servicios: "catalogo/servicio/operadora/",
  depositos: "depositos" 

  };
