import { Component ,OnInit, Inject, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { ComerceService } from 'src/app/services/comerce.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-deposit',
  templateUrl: './modal-deposit.component.html',
  styleUrls: ['./modal-deposit.component.css']
})
export class ModalDepositComponent {
  constructor(
    private ComerceService: ComerceService,
    private CatalogsSvc: CatalogsService,
    public dialogRef: MatDialogRef<ModalDepositComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    console.log(this.data)
  }

  private fb = inject(FormBuilder);

  registerForm = this.fb.group({
    monto: [null, Validators.required],
  });

  onSubmit(): void {
    let data = {
      "email": this.data.correo_electronico,
	    "amount": this.registerForm.controls.monto.value
    };

    this.CatalogsSvc.depositar(data).subscribe({
      next: (res: any) => {
        if(res.result.codigo == 100){
          alert("Deposito en efectivo procesado Correctamente!");
          this.onNoClick()
        }
      },
      error: (e: any) => {
        console.log(e.error);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
