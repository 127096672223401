import { Component ,OnInit, Inject, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { ComerceService } from 'src/app/services/comerce.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-modal-plans-list',
  templateUrl: './modal-plans-list.component.html',
  styleUrls: ['./modal-plans-list.component.css']
})
export class ModalPlansListComponent {
  constructor(
    private ComerceService: ComerceService,
    private CatalogsSvc: CatalogsService,
    public dialogRef: MatDialogRef<ModalPlansListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  
  dataSource:any;
  moneda:string="";
  displayedColumns = ["codigo", "monto_minimo","monto_maximo", "cod_pais", "tipo"];

  private fb = inject(FormBuilder);

  ngOnInit(){
    this.getData();
  }
  ngOnChanges(){
    this.dataSource= new MatTableDataSource(this.dataSource);
  }

  getData(){
    this.CatalogsSvc.getPlans(this.data.codigo).subscribe({
      next: (res: any) => {
        this.dataSource = res.result.datos
        this.dataSource= new MatTableDataSource(this.dataSource);
      },
      error: (e: any) => {
        console.log(e.error);
      }
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
