<div>
    <h2 class="mb-2">Registro de Comercios</h2>


    <form [formGroup]="registerForm" class="w-80 p-3" novalidate (ngSubmit)="onSubmit()">

        <mat-card class="shipping-card">

            <mat-card-header class="form-header">
                <mat-card-title class="">Datos del comercio</mat-card-title>
              </mat-card-header>

            <mat-card-content>

            <div class="row">
                <div class="col-6">
                    <label class="label-custom" for="email"><mat-icon>alternate_email</mat-icon> <span>Correo electrónico</span> </label>
                    <input class="input-custom" matInput placeholder="Correo" formControlName="email" (input)="checkPattern($event)" name="email">
                </div>

                <div class="col-6">
                    <label class="label-custom" for="nombre"><mat-icon>account_box</mat-icon> Nombre</label>
                    <input class="input-custom" matInput placeholder="Nombre" formControlName="nombre" (input)="checkPattern($event)" name="nombre">
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="label-custom" for="password"><mat-icon>lock</mat-icon>Contraseña</label>
                    <input class="input-custom" matInput placeholder="Contraseña" type="password" formControlName="password">
                </div>

                <div class="col-6">
                    <label class="label-custom" for="password2"><mat-icon>lock</mat-icon>Confirmar Contraseña</label>
                    <input class="input-custom" matInput placeholder="Confirmar contraseña" type="password" formControlName="password2">
                    </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <label class="label-custom" for="telefono"><mat-icon> phone</mat-icon>Teléfono</label>
                    <input class="input-custom" matInput placeholder="Teléfono" type="tel" formControlName="telefono" (input)="checkPattern($event)" name="phone">
                    
                </div>

                <div class="col-6">
                    <label class="label-custom" for="documento"><mat-icon>perm_identity</mat-icon>Documento</label>
                    <input class="input-custom" matInput placeholder="Documento" type="text" formControlName="documento" (input)="checkPattern($event)" name="document">
                    
                </div>

            </div>

            <div class="errors-container">
                <p *ngIf="registerForm.controls.email.errors?.['required']">* Correo es requerido.</p>
                <p *ngIf="emailExist">* Este correo ya se encuentra en uso.</p>
                <p *ngIf="emailError">* Formato de correo inválido.</p>
                <p *ngIf="registerForm.controls.nombre.errors?.['required']">* Nombre es requerido.</p>
                <p *ngIf="nameError">* Nombre inválido</p>
                <p *ngIf="registerForm.controls.password.errors?.['required']">* Contraseña es requerido.</p>
                <p *ngIf="registerForm.controls.password2.errors?.['required']">* Confirmación Contraseña es requerido.</p>
                <p *ngIf="registerForm.controls.password.value != registerForm.controls.password2.value">* La contraseña y su confirmación no coinciden </p>
                <p *ngIf="registerForm.controls.telefono.errors?.['required']">* Teléfono es requerido.</p>
                <p *ngIf="phoneError">* Formato de teléfono inválido, ejemplo: 02129999999</p>
                <p *ngIf="registerForm.controls.documento.errors?.['required']">* Documento es requerido.</p>
                <p *ngIf="documentError">* Formato de documento inválido, ejemplo: J-12345678</p> 
            </div>

            <div class="row">
                <mat-card-actions class="d-flex center">
                    <button mat-raised-button 
                       [disabled]="
                       registerForm.controls.email.errors?.['required'] ||
                       emailError ||
                       registerForm.controls.nombre.errors?.['required'] ||
                       nameError || 
                       registerForm.controls.password.errors?.['required'] || 
                       registerForm.controls.password2.errors?.['required'] ||
                       registerForm.controls.telefono.errors?.['required'] ||
                       phoneError ||
                       registerForm.controls.documento.errors?.['required'] ||
                       documentError ||
                       registerForm.controls.password.value != registerForm.controls.password2.value                     
                       "
                    color="primary" class="btn" type="submit">
                    Ingresar
                    </button>
               </mat-card-actions>
            </div>

            </mat-card-content>
        </mat-card>

    </form>
</div>
