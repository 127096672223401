import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './shared/navigation/navigation.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { DashboardComponent } from './shared/dashboard/dashboard.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { LoginComponent } from './components/login/login.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponentComponent } from './login-component/login-component.component';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CatalogComponent } from './catalog/catalog.component';
import { CatalogListComponent } from './components/catalog-list/catalog-list.component';
import { MatTableModule} from '@angular/material/table';
import { RegisterComerceComponent } from './components/register-comerce/register-comerce.component';
import { CreateComerceComponent } from './create-comerce/create-comerce.component';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BalanceCardComponent } from './components/balance-card/balance-card.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NotificationsFormComponent } from './components/notifications-form/notifications-form.component';
import { ModalOperatorsComponent } from './components/modal-operators/modal-operators.component'; 
import {MatDialogModule} from '@angular/material/dialog';
import { ModalPlansComponent } from './components/modal-plans/modal-plans.component';
import { ModalPlansListComponent } from './components/modal-plans-list/modal-plans-list.component';
import { ModalDepositComponent } from './components/modal-deposit/modal-deposit.component';
@NgModule({ 
  declarations: [
    AppComponent,
    NavigationComponent,
    DashboardComponent,
    LoginComponent,
    LoginComponentComponent,
    DashboardHomeComponent,
    CatalogComponent,
    CatalogListComponent,
    RegisterComerceComponent,
    CreateComerceComponent,
    BalanceCardComponent,
    NotificationsFormComponent,
    ModalOperatorsComponent,
    ModalPlansComponent,
    ModalPlansListComponent,
    ModalDepositComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  exports: [MatSortModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
