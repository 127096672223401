<div class="content">

    <div class="padding-login">
        <div class="head">
            <img class="icon-orbita" src="../../assets/icons/orbita-logo.svg">
            <h1 class="text-center text-title">Órbita BackOffice</h1>
        </div>
        <app-login class="d-flex center"></app-login>
    </div>
</div>
<img src="../../assets/icons/shapes.svg">
