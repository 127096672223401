import { Component, OnInit, inject } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { ComerceService } from 'src/app/services/comerce.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'register-comerce',
  templateUrl: './register-comerce.component.html',
  styleUrls: ['./register-comerce.component.css']
})
export class RegisterComerceComponent implements OnInit{
  constructor(
    private ComerceService: ComerceService,
    private UtilsService:UtilsService,
    private CatalogsSvc: CatalogsService
  ) { }
  
  emailReg=new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,}|\.com\.ve)$/);
  nameReg=new RegExp(/^[a-zA-Z0-9\s]{1,60}$/);
  documentReg=new RegExp(/^[JVEG]-\d{1,9}$/);
  phoneReg=new RegExp(/^(0)?(2(12|[4-9]\d)|4(1[246]|2[46]))\d{7}$/);
  emails:string[]=[]
  emailError:boolean=true;
  nameError:boolean=true;
  documentError:boolean=true;
  phoneError:boolean=true;
  emailExist:boolean=false;
  private fb = inject(FormBuilder);
  registerForm = this.fb.group({
    email: [null, Validators.required],
    password: [null, Validators.required],
    password2: [null, Validators.required],
    telefono: [null, Validators.required],
    documento: [null, Validators.required],
    nombre: [null, Validators.required],
  });

  ngOnInit(){
    this.getEmails();
    console.log(this.emails)
  }
  onSubmit(): void {
    let data = {
      jsonrpc: "2.0",
      id: "123",
      method: "",
      params: {  
        correo: this.registerForm.controls.email.value,    
        contrasenia: this.registerForm.controls.password.value,
        nombres:  this.registerForm.controls.nombre.value,
        tipo_documento: "RIF",
        documento: this.registerForm.controls.documento.value,
        cod_pais: "VE",
        telefono: this.registerForm.controls.telefono.value,
      }
    };

    this.ComerceService.createComerce(data).subscribe({
      next: (res: any) => {
        console.log(res.result.datos);
        if(res.result.datos){
          alert("Comercio registrado Correctamente!");
          this.registerForm.reset();
        }
      },
      error: (e: any) => {
        console.log(e.error);
      }
    });
  }

  getEmails(){
    this.CatalogsSvc.getcomerces().subscribe({
      next: (res: any) => {
        let aux:any[]=[]
        res.result.datos.filter((op:any) => op.rol == "Comercio").map((op:any) => {
          aux.push(
            op.correo_electronico 
          )
        })
        this.emails=aux;
      },
      error: (e: any) => {
        console.log(e.error);
      }
    })
  }
  checkPattern(event:any){
    console.log(event.target.name);
    switch (event.target.name){
      case "nombre": 
        (this.nameReg.test(event.target.value) == false) ? this.nameError = true : this.nameError = false;
        break;
      case "email":
        (this.emailReg.test(event.target.value) == false) ? this.emailError = true : this.emailError = false;
        (this.existeEnArray(event.target.value, this.emails)) ? this.emailExist = true : this.emailExist = false;
        break;
      case "phone":
        (this.phoneReg.test(event.target.value) == false) ? this.phoneError = true : this.phoneError = false;
        break;
      case "document":
        (this.documentReg.test(event.target.value) == false) ? this.documentError = true : this.documentError = false;
        break;
    }
  }
  existeEnArray(cadena:string, array:string[]) {
    cadena = cadena.toLowerCase();
    for (var i = 0; i < array.length; i++) {
        if (array[i].toLowerCase() === cadena) {
            return true;
        }
    }
    return false;
  }
}
