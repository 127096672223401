import { Component ,OnInit, Inject, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { ComerceService } from 'src/app/services/comerce.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-plans',
  templateUrl: './modal-plans.component.html',
  styleUrls: ['./modal-plans.component.css']
})
export class ModalPlansComponent {
  constructor(
    private ComerceService: ComerceService,
    private CatalogsSvc: CatalogsService,
    public dialogRef: MatDialogRef<ModalPlansComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  
  paises:any;
  moneda:string="";
  private fb = inject(FormBuilder);

  registerForm = this.fb.group({
    codigo: [null, Validators.required],
    monto_minimo: [null, Validators.required],
    monto_maximo: [null, Validators.required],
    multiplo: [null, Validators.required],
  });

  ngOnInit(){
    this.getCountries();
  }
  onSubmit(): void {
    let data = {
      "jsonrpc": "2.0",
      "id": "123",
      "method": "",
      "params": {

        "codigo": this.registerForm.controls.codigo.value,
        "codigo_operadora": this.data.codigo,
        "descripcion": null,
        "monto_minimo": this.registerForm.controls.monto_minimo.value,
        "monto_minimo_usd": null,
        "monto_maximo": this.registerForm.controls.monto_maximo.value,
        "monto_maximo_usd": null,
        "multiplo": this.registerForm.controls.multiplo.value,
        "moneda": this.moneda,
        "tipo": "MUL",
        "base_comision": 0.05,
        "tipo_base_comision": "%"
      }
    };

    this.CatalogsSvc.newService(data).subscribe({
      next: (res: any) => {
        if(res.result.codigo == 100){
          alert("Plan registrado Correctamente!");
          this.onNoClick()
        }
      },
      error: (e: any) => {
        console.log(e.error);
      }
    });
  }

  getCountries(){
    this.CatalogsSvc.getCountries().subscribe({
      next: (res: any) => {
        res.result.datos.paises.map((pais:any)=>{
          if(pais.nombre == this.data.pais){
            this.moneda = pais.simbolo_moneda
          }
        })
      },
      error: (e: any) => {
        console.log(e.error);
      }
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
