<div *ngIf="user.rol == 'Comercio'">
    <div class="p-3 d-flex mb-2">
      <mat-card class="w-40">
        <mat-card-header><h4>Balance USD:</h4></mat-card-header>
        <mat-card-content><h2 class="amount">{{balanceInfo?.balance_usd}} USD</h2></mat-card-content>
      </mat-card>
    </div>
  
  <div class="p-3 d-flex">
    <mat-card class="w-auto">
      <mat-card-header><h4>API KEY:</h4></mat-card-header>
      <mat-card-content>
        <h2 class="amount">
          <span *ngIf="showKey">{{apiKey}}</span> 
          <span *ngIf="!showKey">*******************</span>  
          
          <mat-icon *ngIf="!showKey" (click)="didShowKey()">remove_red_eye</mat-icon> 	
          <mat-icon *ngIf="showKey" (click)="didShowKey()">visibility_off</mat-icon>
        </h2> 
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div *ngIf="user.rol == 'Admin'">

  <h2 class="mb-2">Balance General</h2>
  <div class="p-3 d-flex mb-2">
    <mat-card class="w-40">
      <mat-card-header><h4>Balance USD:</h4></mat-card-header>
      <mat-card-content><h2 class="amount">{{cambiarComa(balanceInfo?.balance_usd.toString())}} USD</h2></mat-card-content>
    </mat-card>
  </div>

  <div class="p-3 d-flex mb-2">
    <mat-card class="w-40">
      <mat-card-header><h4>Balance Huuray:</h4></mat-card-header>
      <mat-card-content><h2 class="amount" *ngIf="balanceHurrayInfo?.Balances[0].Balance">{{insertarComa(balanceHurrayInfo?.Balances[0].Balance.toString())}} USD</h2></mat-card-content>
    </mat-card>
  </div>

  <div class="p-3 d-flex mb-2">
    <mat-card class="w-40">
      <mat-card-header><h4>Balance DING:</h4></mat-card-header>
      <mat-card-content><h2 class="amount" *ngIf="balanceDingInfo?.Balance">{{cambiarComa(balanceDingInfo?.Balance.toString())}} USD</h2></mat-card-content>
    </mat-card>
  </div>
</div>


