import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../enviroment/enviroment.dev';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class ComerceService {

  constructor(
    private http:HttpClient,
    private utilSvc: UtilsService) { }
  
  getHeaders(){
    let user = this.utilSvc.getFromLocalStorage("user");
    return this.utilSvc.getHttpHeaders(user.token)
  }

  createComerce(data:any){
    return this.http.post(environment.baseUrl + environment.client, data, {headers: this.getHeaders()});
  }

  getApiKey(){
    return this.http.get(environment.baseUrl + environment.api_key, {headers: this.getHeaders()});
  }

  createApiKey(){
    return this.http.post(environment.baseUrl + environment.api_key, {}, {headers: this.getHeaders()});
  }
  getOrders(){
    return this.http.get(environment.baseUrl + environment.orders, {headers: this.getHeaders()});
  }

  getAllOrders(){
    return this.http.get(environment.baseUrl + environment.allOrders, {headers: this.getHeaders()});
  }
  
  getBalance(){
    return this.http.post(environment.baseUrl + environment.balance, {},{headers: this.getHeaders()});
  }
  getBalanceHuuray(){
    return this.http.get(environment.baseUrl + environment.balance_hurray, {headers: this.getHeaders()});
  }
  getBalanceDing(){
    return this.http.get(environment.baseUrl + environment.balance_ding, {headers: this.getHeaders()});
  }
  
}
