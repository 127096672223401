import { Component, OnInit, Input, ViewChild,AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ModalOperatorsComponent } from '../modal-operators/modal-operators.component';
import { ModalPlansComponent } from '../modal-plans/modal-plans.component';
import { ModalPlansListComponent } from '../modal-plans-list/modal-plans-list.component';
import { ModalDepositComponent } from '../modal-deposit/modal-deposit.component';
export interface Dessert {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}
@Component({
  selector: 'catalog-list', 
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.css']
})
export class CatalogListComponent implements OnInit {
  title = 'app';
  modal : any;
  constructor(public dialog: MatDialog) {}
  @Input() type: any;
  @Input() data: any;
  sortedData: Dessert[]=[];
  displayedColumns = ["codigo", "nombre", "pais", "acciones"];
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('paginator') paginator = new MatPaginator(new MatPaginatorIntl);
  dataSource:any;
  length = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];

  ngOnInit() {
    if(this.type == "Ordenes"){this.displayedColumns=["fecha", "tipo", "hash", "total_usd", "operadora", "pais", "tipo_servicio", "destino", "estatus"];}
    if(this.type == "Giftcards"){this.displayedColumns=["codigo", "nombre", "proveedor"];}
    if(this.type == "Subscripciones"){this.displayedColumns=["codigo", "nombre"];}
    if(this.type == "comercios" || this.type == "Usuarios"){this.displayedColumns=["nombre", "correo_electronico", "tipo_documento", "documento", "telefono", "balance_usd", "fondear"];}
    this.empTbSort.disableClear = true;
    this.paginator._intl.itemsPerPageLabel = 'Prueba';
  }
  ngOnChanges(){
    this.dataSource= new MatTableDataSource(this.data);
    this.sortData({direction :"desc", active: "fecha"})
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Items por página';
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      this.dataSource= new MatTableDataSource(this.data);
      return;
    }

    this.dataSource = data.sort((a:any, b:any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'codigo':
          return this.compare(a.codigo, b.codigo, isAsc);
        case 'nombre':
          return this.compare(a.nombre, b.nombre, isAsc);
        case 'pais':
          return this.compare(a.pais, b.pais, isAsc);
        case 'fecha':
          return this.compare(a.fecha, b.fecha, isAsc);
        case 'tipo':
          return this.compare(a.tipo, b.tipo, isAsc);
        case 'total_usd':
          return this.compare(a.total_usd, b.total_usd, isAsc);
        case 'operadora':
          return this.compare(a.operadora, b.operadora, isAsc);
        case 'tipo_servicio':
          return this.compare(a.tipo_servicio, b.tipo_servicio, isAsc);
        case 'nombres':
          return this.compare(a.nombres, b.nombres, isAsc);
        case 'apellidos':
          return this.compare(a.apellidos, b.apellidos, isAsc);  
        case 'correo_electronico':
          return this.compare(a.correo_electronico, b.correo_electronico, isAsc);  
        case 'tipo_documento':
          return this.compare(a.tipo_documento, b.tipo_documento, isAsc);  
        case 'documento':
          return this.compare(a.documento, b.documento, isAsc);  
        case 'telefono':
          return this.compare(a.telefono, b.telefono, isAsc);  
        case 'destino':
          return this.compare(a.destino, b.destino, isAsc);  
        case 'estatus':
        return this.compare(a.estatus, b.estatus, isAsc);  
        case 'hash':
        return this.compare(a.hash, b.hash, isAsc);  
        default:
          return 0;
      }
    });
    this.dataSource= new MatTableDataSource(this.dataSource);
    this.dataSource.paginator = this.paginator;
  }
  
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  formatName(string:string){
    return string.replace(/_/g, ' ').replace(/^./, string[0].toUpperCase()).replace("electronico", "electrónico").replace("Telefono", "Teléfono").replace("Codigo", "Código").replace("Pais", "País");
  }
  openDialog() {
    const dialogRef = this.dialog.open(ModalOperatorsComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogDeposit(usuario:any){
    const dialogRef = this.dialog.open(ModalDepositComponent, {data: usuario});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogPlan(operadora:any){
    const dialogRef = this.dialog.open(ModalPlansComponent, {data: operadora});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  openDialogPlanList(operadora:any){
    const dialogRef = this.dialog.open(ModalPlansListComponent, {data: operadora});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  cerrar() {
    this.modal.close();
  }
} 
