<form [formGroup]="addressForm" class="w-80" novalidate (ngSubmit)="onSubmit()">

  <mat-card class="shipping-card">

    <mat-card-header class="form-header">
      <mat-card-title class="mb-2">Inicio de Sesión</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-icon class="width-icon">supervisor_accounts</mat-icon> 
          <mat-form-field class="full-width">
            <input matInput placeholder="Correo" formControlName="email" (input)="checkPattern($event)">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-icon class="width-icon">lock</mat-icon> 
          <mat-form-field class="full-width">
            <input matInput placeholder="Contraseña" type="password" formControlName="password">
          </mat-form-field>
        </div>
      </div>
    <mat-card-actions class="d-flex center">
      <button mat-raised-button color="primary" class="btn" 
      [disabled]="
      addressForm.controls.email.value == null ||
      addressForm.controls.password.value  == null || emailError" 
      type="submit">Ingresar</button>
    </mat-card-actions>
    </mat-card-content>

    <mat-card-footer *ngIf="errors != ''">
      <h4 class="text-error">{{errors}}</h4>  
    </mat-card-footer>
    <mat-card-footer *ngIf="emailError">
      <h4 class="text-error">Correo inválido</h4>  
    </mat-card-footer>
    
  </mat-card>

</form>
