import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../enviroment/enviroment.dev';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService {

  constructor(
    private http:HttpClient,
    private utilSvc: UtilsService) { }


  getHeaders(){
    let user = this.utilSvc.getFromLocalStorage("user");
    return this.utilSvc.getHttpHeaders(user.token)
  }

  getOperators(){
    return this.http.get(environment.baseUrl + environment.operators, {headers: this.getHeaders()});
  }

  newOperator(data:any){
    return this.http.post(environment.baseUrl + environment.operators, data, {headers: this.getHeaders()});
  }
  newService(data:any){
    return this.http.post(environment.baseUrl + environment.servicio, data, {headers: this.getHeaders()});
  }

  getCountries(){
    return this.http.get(environment.baseUrl + environment.paises, {headers: this.getHeaders()});
  }

  getPlans(operadora:string){
    return this.http.get(environment.baseUrl + environment.servicios + operadora, {headers: this.getHeaders()});
  }
  getSuscriptions(){
    return this.http.get(environment.baseUrl + environment.catalogo_suscripcion, {headers: this.getHeaders()});
  }

  getcomerces(){
    return this.http.get(environment.baseUrl + environment.client, {headers: this.getHeaders()});
  }
  depositar(data:any){
    return this.http.post(environment.baseUrl + environment.depositos, data, {headers: this.getHeaders()});
  }
}
