<div class="content">
    <form [formGroup]="registerForm" class="w-80 p-3" novalidate (ngSubmit)="onSubmit()">

        <mat-card class="shipping-card">

            <mat-card-header class="form-header">
                <mat-card-title class="">Datos del Plan</mat-card-title>
              </mat-card-header>

            <mat-card-content>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Código</span> </label>
                    <input class="input-custom" matInput placeholder="Código" formControlName="codigo" name="codigo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Monto minímo</span> </label>
                    <input class="input-custom" matInput placeholder="Monto minímo" type="number" formControlName="monto_minimo" name="monto_minimo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Monto máximo</span> </label>
                    <input class="input-custom" matInput placeholder="Monto máximo" type="number" formControlName="monto_maximo" name="monto_maximo">
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Multiplo</span> </label>
                    <input class="input-custom" matInput placeholder="Multiplo" type="number" formControlName="multiplo" name="multiplo">
                </div>
            </div>

            <div class="errors-container">
                
            </div>

            <div class="row">
                <mat-card-actions class="d-flex center">
                    <button mat-raised-button 
                       [disabled]="
                       registerForm.controls.codigo.errors?.['required'] || 
                       registerForm.controls.monto_minimo.errors?.['required'] || 
                       registerForm.controls.monto_maximo.errors?.['required'] || 
                       registerForm.controls.multiplo.errors?.['required']
                       "
                    color="primary" class="btn" type="submit">
                    Registrar
                    </button>
               </mat-card-actions>
            </div>

            </mat-card-content>
        </mat-card>

    </form>
</div>