import { Component } from '@angular/core';

@Component({
  selector: 'notifications-form',
  templateUrl: './notifications-form.component.html',
  styleUrls: ['./notifications-form.component.css']
})
export class NotificationsFormComponent {

}
