import { Component , OnInit} from '@angular/core';
import { ComerceService } from '../../services/comerce.service';
import { UtilsService } from 'src/app/services/utils.service';
@Component({
  selector: 'balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.css']
})
export class BalanceCardComponent implements OnInit {

  constructor(private ComerceSvc:ComerceService, private UtilsService: UtilsService){}
  balanceInfo:any;
  balanceHurrayInfo:any;
  balanceDingInfo:any;
  apiKey:any;
  showKey:boolean=false;
  user:any;
  ngOnInit(){
    this.getBalance();
    this.getBalanceHuuray();
    this.getBalanceDing();
    this.createApiKey();
    this.getApiKey();
    this.user = this.UtilsService.getFromLocalStorage("user");
  }

  getBalance(){
    this.ComerceSvc.getBalance().subscribe({
      next: (res: any) => {
        this.balanceInfo = res.result.datos;
      },
      error: (e: any) => {
        console.log(e.error);
      }
    })
  }

  getBalanceHuuray(){
    this.ComerceSvc.getBalanceHuuray().subscribe({
      next: (res: any) => {
        this.balanceHurrayInfo = res.result.datos;
      },
      error: (e: any) => {
        console.log(e.error);
      }
    })
  }
  getBalanceDing(){
    this.ComerceSvc.getBalanceDing().subscribe({
      next: (res: any) => {
        this.balanceDingInfo = res.result.datos;
      },
      error: (e: any) => {
        console.log(e.error);
      }
    })
  }

  getApiKey(){
    this.ComerceSvc.getApiKey().subscribe({
      next: (res: any) => {
        if(res.result.datos){
          this.apiKey =  res.result.datos.api_key;
        } 
      },
      error: (e: any) => {
        console.log(e.error);
      }
    })
   
  }
  createApiKey(){
    this.ComerceSvc.createApiKey().subscribe({
      next: (res: any) => {
        if(res.result.datos){
          this.apiKey =  res.result.datos.api_key;
        } 
      },
      error: (e: any) => {
        console.log(e.error);
      }
    })
  }
  didShowKey(){
    this.showKey=!this.showKey
  }
  insertarComa(cadena:string) {
    return cadena.slice(0, -2) + "," + cadena.slice(-2);
  }
  cambiarComa(cadena:string){
    return cadena.replace(".", ",");
  }
}
