import { Component, inject, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { ComerceService } from 'src/app/services/comerce.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit{
  urlTree:any;
  type:any;
  path:any;
  role:any;
  data:any[]=[];
  user:any;
  constructor(
    private ActivatedRoute: ActivatedRoute,
    private utilService: UtilsService,
    private comerceSvc :ComerceService,
    private CatalogsSvc: CatalogsService
  ) { }
  private breakpointObserver = inject(BreakpointObserver);
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit() {
    this.checkLog();
    this.urlTree = this.ActivatedRoute;
    this.type = this.urlTree.snapshot.params.type;
    this.path = this.ActivatedRoute.snapshot.routeConfig?.path;
    this.role = this.utilService.getFromLocalStorage("user").rol;
    this.user = this.utilService.getFromLocalStorage("user");

    if(this.role == "Comercio") {
      this.getCatalogBytype(this.type);
    }

    if(this.role == "Admin") {
      this.getCatalogBytype(this.type);
    }
  }

  getCatalogBytype(type:string){
    if(type == "Operadoras"){
      this.CatalogsSvc.getOperators().subscribe({
        next: (res: any) => {
          let aux:any[]=[]
          console.log(res.result.datos);
          res.result.datos.filter((op:any) => op.codigo_tipo_servicio == "TEM").map((op:any) => {
            aux.push(
              {
              codigo: op.codigo,
              nombre: op.nombre, 
              pais: op.pais,
              }
            )
          })
          this.data=aux;
        },
        error: (e: any) => {
          console.log(e.error);
        }
      })
    }
    if(type == "Giftcards"){
      this.CatalogsSvc.getOperators().subscribe({
        next: (res: any) => {
          let aux:any[]=[]
          console.log(res.result.datos);
          res.result.datos.filter((op:any) => op.codigo_tipo_servicio == "GIF").map((op:any) => {
            aux.push(
              {
              codigo: op.codigo,
              nombre: op.nombre, 
              proveedor: op.servicio,
              }
            )
          })
          this.data=aux;
        },
        error: (e: any) => {
          console.log(e.error);
        }
      })
    }
    if(type == "Subscripciones"){
      this.CatalogsSvc.getSuscriptions().subscribe({
        next: (res: any) => {
          let aux:any[]=[]
          console.log(res.result.datos);
          res.result.datos.suscripciones.map((op:any) => {
            aux.push(
              {
              codigo: op.codigo,
              nombre: op.nombre, 
              }
            )
          })
          this.data=aux;
        },
        error: (e: any) => {
          console.log(e.error);
        }
      })
    }
    if(type == "comercios"){
      this.CatalogsSvc.getcomerces().subscribe({
        next: (res: any) => {
          let aux:any[]=[]
          console.log(res.result.datos);
          res.result.datos.filter((op:any) => op.rol == "Comercio").map((op:any) => {
            aux.push(
              {
                correo_electronico: op.correo_electronico,
                nombre: op.nombres, 
                apellidos: op.apellidos, 
                rol: op.rol, 
                suscripcion: op.suscripcion, 
                tipo_documento: op.tipo_documento, 
                documento: op.documento,
                telefono: op.telefono,
                balance_usd: op.balance_usd
              }
            )
          })
          this.data=aux;
        },
        error: (e: any) => {
          console.log(e.error);
        }
      })
    }
    if(type == "Usuarios"){
      this.CatalogsSvc.getcomerces().subscribe({
        next: (res: any) => {
          let aux:any[]=[]
          console.log(res.result.datos);
          res.result.datos.filter((op:any) => op.rol == "Cliente").map((op:any) => {
            aux.push(
              {
                correo_electronico: op.correo_electronico,
                nombre: op.nombres, 
                apellidos: op.apellidos, 
                rol: op.rol, 
                suscripcion: op.suscripcion, 
                tipo_documento: op.tipo_documento, 
                documento: op.documento,
                telefono: op.telefono
              }
            )
          })
          this.data=aux;
        },
        error: (e: any) => {
          console.log(e.error);
        }
      })
    } 
    if(type == "Ordenes"){

      if(this.role == "Admin"){
      this.comerceSvc.getAllOrders().subscribe({
        next: (res: any) => {
          let aux:any[]=[]
          console.log(res.result.datos);
          res.result.datos.map((op:any) => {

            let status;
            if(op.cod_estatus_servicio){ 
              if(op.cod_estatus_servicio == 100 || op.cod_estatus_servicio == 104){
                status = "Aprobada"
              }else{
                status = "Rechazada"
              }
            }
            else {
              status = "N/A"
            }
            aux.push(
              {
              fecha: op.fecha,
              tipo: op.tipo_transaccion, 
              hash: op.hash,
              total_usd: op.total_usd,
              operadora: op.operadora ? op.operadora : "N/A" ,
              tipo_servicio: op.tipo_servicio,
              pais: op.pais ? op.pais : "N/A" ,
              destino: op.destino ? op.destino : "N/A",
              estatus: status
              }
            )
          })
          this.data=aux;
        },
        error: (e: any) => {
          console.log(e.error);
        }
      })}else{
        this.comerceSvc.getOrders().subscribe({
          next: (res: any) => {
            let aux:any[]=[]
            console.log(res.result.datos);
            res.result.datos.map((op:any) => {
  
              let status;
              if(op.cod_estatus_servicio){ 
                if(op.cod_estatus_servicio == 100 || op.cod_estatus_servicio == 104){
                  status = "Aprobada"
                }else{
                  status = "Rechazada"
                }
              }
              else {
                status = "N/A"
              }
              aux.push(
                {
                fecha: op.fecha,
                tipo: op.tipo_transaccion, 
                hash: op.hash,
                total_usd: op.total_usd,
                operadora: op.operadora ? op.operadora : "N/A" ,
                tipo_servicio: op.tipo_servicio,
                pais: op.pais ? op.pais : "N/A" ,
                destino: op.destino ? op.destino : "N/A",
                estatus: status
                }
              )
            })
            this.data=aux;
          },
          error: (e: any) => {
            console.log(e.error);
          }
        })
      }
    }
  }

  checkLog(){
    if(!this.utilService.getFromLocalStorage("user")){
      this.utilService.routerLink("auth")
    }
  }

  logOut(){
    this.utilService.removeLocalStorage("user")
    this.utilService.removeLocalStorage("api_key")
    this.utilService.routerLink("auth")
  }

} 
