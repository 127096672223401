<div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Position Column -->
        <ng-container matColumnDef="codigo">
          <th mat-header-cell *matHeaderCellDef> Código </th>
          <td mat-cell *matCellDef="let element"> {{element.codigo}} </td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        
        <ng-container matColumnDef="monto_minimo">
            <th mat-header-cell *matHeaderCellDef> Monto minimo </th>
            <td mat-cell *matCellDef="let element"> {{element.monto_minimo}} </td>
          </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="monto_maximo">
          <th mat-header-cell *matHeaderCellDef> Monto máximo </th>
          <td mat-cell *matCellDef="let element"> {{element.monto_maximo}} </td>
        </ng-container>

        <ng-container matColumnDef="cod_pais">
            <th mat-header-cell *matHeaderCellDef> País </th>
            <td mat-cell *matCellDef="let element"> {{element.cod_pais}} </td>
          </ng-container>

          <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
          </ng-container>
  
          
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

</div>