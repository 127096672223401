<div class="content">
    <form [formGroup]="registerForm" class="w-80 p-3" novalidate (ngSubmit)="onSubmit()">

        <mat-card class="shipping-card">

            <mat-card-header class="form-header">
                <mat-card-title class="">Fondear usuario: {{data.correo_electronico}}</mat-card-title>
              </mat-card-header>

            <mat-card-content>


            <div class="row">
                <div class="col-12">
                    <label class="label-custom" for="email"><span>Monto: </span> </label>
                    <input class="input-custom" matInput placeholder="Monto" type="number" formControlName="monto" name="monto">
                </div>
            </div>

            <div class="errors-container">
                
            </div>

            <div class="row">
                <mat-card-actions class="d-flex center">
                    <button mat-raised-button 
                       [disabled]="!registerForm.controls.monto.value"
                    color="primary" class="btn" type="submit">
                    Fondear
                    </button>
               </mat-card-actions>
            </div>

            </mat-card-content>
        </mat-card>

    </form>
</div>