<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
      
    <mat-toolbar class="h-auto">

      <!--mat-icon class="icon-menu" aria-label="Side nav toggle icon">menu</mat-icon--> 
      <div class="user-container">
        <mat-icon class="icon-account">account_circle</mat-icon>
        <div class="text-full">
          <h2 >{{user.nombres}} {{user.apellidos}}  </h2>
        </div>
        
      </div>
    </mat-toolbar>

    <mat-nav-list *ngIf="role == 'Admin'">
      <a class="d-flex menu-item" href="home"><mat-icon class="icon-menu">home</mat-icon>Inicio</a>
      <a class="d-flex menu-item" href="catalog/comercios"><mat-icon class="icon-menu">assignment</mat-icon>Catálogo de comercios</a>
      <a class="d-flex menu-item" href="createComerce"><mat-icon class="icon-menu">playlist_add</mat-icon>Registrar de un comercio</a>
      <!--a class="d-flex menu-item" href="catalog/user"><mat-icon class="icon-menu">add_circle</mat-icon>Abonar saldo a un comercio</a-->
      <a class="d-flex menu-item" href="catalog/Operadoras"><mat-icon class="icon-menu">assignment</mat-icon>Catálogo de países y operadoras</a>
      <a class="d-flex menu-item" href="catalog/Usuarios"><mat-icon class="icon-menu">fingerprint</mat-icon>Catálogo de usuarios</a>
      <a class="d-flex menu-item" href="catalog/Giftcards"><mat-icon class="icon-menu">card_giftcard</mat-icon> Catálogo de Giftcard</a> 
      <a class="d-flex menu-item" href="notifications"><mat-icon class="icon-menu">adb</mat-icon>  Enviar notificaciones </a> 
      <a class="d-flex menu-item" href="catalog/Subscripciones"><mat-icon class="icon-menu">list</mat-icon>Listado de suscripciones </a> 
      <a class="d-flex menu-item" href="catalog/Ordenes"><mat-icon class="icon-menu">list</mat-icon>Listado de Ordenes </a> 
      <a class="d-flex menu-item" (click)="logOut()"><mat-icon class="icon-menu">logout</mat-icon>Salir</a> 
    </mat-nav-list>

    <mat-nav-list *ngIf="role == 'Comercio'">
      <a class="d-flex menu-item" href="home"><mat-icon class="icon-menu">home</mat-icon>Inicio</a>
      <a class="d-flex menu-item" href="catalog/Ordenes"><mat-icon class="icon-menu">list</mat-icon> Ordenes</a> 
      <a class="d-flex menu-item" (click)="logOut()"><mat-icon class="icon-menu">logout</mat-icon>Salir</a> 
    </mat-nav-list>
    
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img class="icon-orbita-sm" src="../../assets/icons/orbita-logo.svg">
      <span>Orbita BackOffice</span>
    </mat-toolbar>

    <app-dashboard></app-dashboard>

    <!--CONTENIDO DEPENDIENDO DEL LA RUTA-->

    <balance-card *ngIf="path == 'home'"> </balance-card> 
    <!--Listas para los catalogos-->
    <catalog-list *ngIf="path == 'catalog/:type'" [type]="type" [data]="data"></catalog-list>

    <!--Formulario de registro de Comercios-->
    <register-comerce *ngIf="path == 'createComerce'"></register-comerce>


    <!--Formulario de envio de notificaciones-->
    <notifications-form *ngIf="path == 'notifications'"></notifications-form>

  </mat-sidenav-content>
</mat-sidenav-container>
