<div>
    <h2 class="mb-2">Enviar notificaciones push</h2>


    <form class="w-80 p-3" >

        <mat-card class="shipping-card">

            <mat-card-header class="form-header">
                <mat-card-title class="">Datos de la notificación</mat-card-title>
              </mat-card-header>

            <mat-card-content>

            <div class="row">
                <div class="col-6">
                    <label class="label-custom" for="Texto"><mat-icon>title</mat-icon> <span>Texto</span> </label>
                    <input [disabled]="true" class="input-custom"  placeholder="Texto" name="Texto">
                </div>

                <div class="col-6">
                    <label class="label-custom" for="nombre"><mat-icon>account_box</mat-icon> Nombre</label>
                    <input [disabled]="true" class="input-custom"  placeholder="Nombre"  name="nombre">
                </div>
            </div>


            <div class="errors-container">
                <p>* Función no disponible.</p>
            </div>

            <div class="row">
                <mat-card-actions class="d-flex center">
                    <button mat-raised-button 
                       [disabled]="true                 
                       "
                    color="primary" class="btn" type="submit">
                    Ingresar
                    </button>
               </mat-card-actions>
            </div>

            </mat-card-content>
        </mat-card>

    </form>
</div>