import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private router: Router
  ) { }

  getHttpHeaders(token?:any){
    if(!token){
      let header = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-Origin', '*');
      return header;
    }else{
      let header = new HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-Origin', '*').set('Authorization', token);
      return header 
    }
     
  }

  /**
   * It saves an object to local storage.
   * @param {string} name - The name of the object you want to save.
   * @param {any} object - the object you want to save
   */
  saveLocalStorage(name: string, object: any) {
    localStorage.setItem(name, JSON.stringify(object))
  }

  removeLocalStorage(name: string) {
    localStorage.removeItem(name)
  }
  /**
   * It takes a string as an argument, and returns the value of the localStorage item with the same name
   * as the string
   * @param {string} name - The name of the item you want to get from local storage.
   * @returns The value of the item in local storage with the name that is passed in.
   */
  getFromLocalStorage(name: string) {
    return JSON.parse(localStorage.getItem(name) as string);
  }

  //======= Router =======
  routerLink(url: string) {
    this.router.navigateByUrl(url);
  }
}