import { Component ,OnInit, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { ComerceService } from 'src/app/services/comerce.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-operators',
  templateUrl: './modal-operators.component.html',
  styleUrls: ['./modal-operators.component.css']
})
export class ModalOperatorsComponent {
  constructor(
    private ComerceService: ComerceService,
    private CatalogsSvc: CatalogsService,
    public dialogRef: MatDialogRef<ModalOperatorsComponent>
  ) { }
  
  paises:any;
  private fb = inject(FormBuilder);

  registerForm = this.fb.group({
    codigo: [null, Validators.required],
    nombre: [null, Validators.required],
    descripcion: [null, Validators.required],
    image: [null, Validators.required],
    pais: [null, Validators.required],
    moneda: [null, Validators.required],
  });

  ngOnInit(){
    this.getCountries();
  }
  onSubmit(): void {
    let data = {
      "jsonrpc": "2.0",
      "id": "123",
      "method": "",
      "params": {
        "codigo": this.registerForm.controls.codigo.value,
        "nombre": this.registerForm.controls.nombre.value,
        "descripcion": this.registerForm.controls.descripcion.value,
        "cod_tipo_servicio": "TEM",
        "cod_proveedor": "DING",
        "tipo_recarga": "INT",
        "cod_pais": this.registerForm.controls.pais.value,
        "cod_moneda": "VES",
        "comision": 0.02,
        "tipo_comision": "%",
        "regex_validacion": "^58([0-9]{10})$",
        "img": this.registerForm.controls.image.value
      }
    };

    this.CatalogsSvc.newOperator(data).subscribe({
      next: (res: any) => {
        if(res.result.codigo == 100){
          alert("Operadora registrada Correctamente!");
          this.onNoClick()
        }
      },
      error: (e: any) => {
        console.log(e.error);
      }
    });
  }

  getCountries(){
    this.CatalogsSvc.getCountries().subscribe({
      next: (res: any) => {
        this.paises = res.result.datos.paises
      },
      error: (e: any) => {
        console.log(e.error);
      }
    });
  }
  checkPattern(event:any){
    console.log(event.target.name);
    switch (event.target.name){
      case "nombre": 
        break;
    }
  }
  selectPais(event:any){
    this.registerForm.controls.pais.setValue(event.target.value);
    this.paises.map((pais:any)=>{
      if(pais.codigo_iso == event.target.value ){
        this.registerForm.controls.moneda.setValue(pais.simbolo_moneda);
      }
    })
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
