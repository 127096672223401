import { Component, inject } from '@angular/core';

import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ComerceService } from 'src/app/services/comerce.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {

  constructor(
    private authSvc: AuthService,
    private UtilsService:UtilsService,
    private comerceSvc: ComerceService
  ) { }

  errors: string ="";
  emailReg=new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,}|\.com\.ve)$/);
  emailError:boolean=false

  private fb = inject(FormBuilder);
  addressForm = this.fb.group({
    email: [null, Validators.required,Validators.email],
    password: [null, Validators.required],
  });

  onSubmit(): void {
    let data = {
      jsonrpc: "2.0",
      id: "123",
      method: "",
      params: {  
        correo: this.addressForm.controls.email.value,  
        contrasenia:this.addressForm.controls.password.value  
      }
    };

    this.authSvc.auth(data).subscribe({
      next: (res: any) => {
        if(res.result.datos && res.result.codigo == "105"){
          this.UtilsService.saveLocalStorage('user', res.result.datos)
          this.UtilsService.routerLink("home");
          this.comerceSvc.getApiKey().subscribe({
            next: (res: any) => {
              if(res.result.datos){
                this.UtilsService.saveLocalStorage("api_key", res.result.datos.api_key)
              } 
            },
            error: (e: any) => {
              console.log(e.error);
            }
          })
         
        }else {
          if(res.error){
            this.errors = res.error.respuesta
          }else{
            this.errors = res.result.respuesta
          }
        }
      },
      error: (e: any) => {
        this.errors = e.error.datos.respuesta
      }
    });
  }
  checkPattern(event:any){
    (this.emailReg.test(event.target.value) == false) ? this.emailError = true : this.emailError = false;
  }
}
